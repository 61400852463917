import { ChargeType } from '$lib/gen/rates/v1/rate_pb'
import type { PriceItemType } from '$lib/graphql/types'

export const feeDescriptionTypeMapGql = new Map<PriceItemType, string>([
  ['ADJUSTMENT', 'Adjustment'],
  ['AIRHEART_SERVICE_FEE', 'Affiliate Service Charge'],
  ['AIRLINE_TAX', 'Airline Tax'],
  ['AIRPORT_TAX', 'Airport Tax'],
  ['BASE_RATE', 'Base Rate'],
  ['BOOKING_FEE', 'Booking Fee'],
  ['CLEANING_FEE', 'Cleaning Fee'],
  ['COMMISSION', 'Commission'],
  ['COMPENSATION', 'Compensation'],
  ['CREDIT_CARD_FEE', 'Credit Card Fee'],
  ['DEPOSIT', 'Deposit'],
  ['DISCOUNT', 'Discount'],
  ['EXTRA_PERSON_FEE', 'Extra Person Fee'],
  ['FACILITY_FEE', 'Facility Fee'],
  ['FEE', 'Fee'],
  ['LOCAL_TAX', 'Local Tax'],
  ['MANDATORY_FEE', 'Mandatory Fee'],
  ['MANDATORY_TAX', 'Mandatory Tax'],
  ['PROPERTY_FEE', 'Property Fee'],
  ['RECOVERY_CHARGES_AND_FEES', 'Recovery Charges and Fees'],
  ['RESORT_FEE', 'Resort Fee'],
  ['SALES_TAX', 'Sales Tax'],
  ['SERVICE_FEE', 'Service Fee'],
  ['SURCHARGE', 'Surcharge'],
  ['TAX', 'Tax'],
  ['TAX_AND_SERVICE_FEE', 'Tax and Service Fee'],
  ['TOURISM_TAX', 'Tourism Tax'],
  ['TRAVELER_SERVICE_FEE', 'Traveler Service Fee'],
  ['UTILITY_FEE', 'Utility Fee'],
  ['UNSPECIFIED', 'Unspecified Fee'],
])

export function getDescriptionGql(type: PriceItemType): string {
  return feeDescriptionTypeMapGql.get(type) || 'Unspecified Fee'
}

export const feeDescriptionTypeMap = new Map<ChargeType, string>([
  [ChargeType.ADJUSTMENT, 'Adjustment'],
  [ChargeType.AIRHEART_SERVICE_FEE, 'Affiliate Service Charge'],
  [ChargeType.AIRLINE_TAX, 'Airline Tax'],
  [ChargeType.AIRPORT_TAX, 'Airport Tax'],
  [ChargeType.BASE_RATE, 'Base Rate'],
  [ChargeType.BOOKING_FEE, 'Booking Fee'],
  [ChargeType.CLEANING_FEE, 'Cleaning Fee'],
  [ChargeType.COMMISSION, 'Commission'],
  [ChargeType.COMPENSATION, 'Compensation'],
  [ChargeType.CREDIT_CARD_FEE, 'Credit Card Fee'],
  [ChargeType.DEPOSIT, 'Deposit'],
  [ChargeType.DISCOUNT, 'Discount'],
  [ChargeType.EXTRA_PERSON_FEE, 'Extra Person Fee'],
  [ChargeType.FACILITY_FEE, 'Facility Fee'],
  [ChargeType.FEE, 'Fee'],
  [ChargeType.LOCAL_TAX, 'Local Tax'],
  [ChargeType.MANDATORY_FEE, 'Mandatory Fee'],
  [ChargeType.MANDATORY_TAX, 'Mandatory Tax'],
  [ChargeType.PROPERTY_FEE, 'Property Fee'],
  [ChargeType.RECOVERY_CHARGES_AND_FEES, 'Recovery Charges and Fees'],
  [ChargeType.RESORT_FEE, 'Resort Fee'],
  [ChargeType.SALES_TAX, 'Sales Tax'],
  [ChargeType.SERVICE_FEE, 'Service Fee'],
  [ChargeType.SURCHARGE, 'Surcharge'],
  [ChargeType.TAX, 'Tax'],
  [ChargeType.TAX_AND_SERVICE_FEE, 'Tax and Service Fee'],
  [ChargeType.TOURISM_TAX, 'Tourism Tax'],
  [ChargeType.TRAVELER_SERVICE_FEE, 'Traveler Service Fee'],
  [ChargeType.UTILITY_FEE, 'Utility Fee'],
  [ChargeType.UNSPECIFIED, 'Unspecified Fee'],
])

export function getDescription(type: ChargeType): string {
  return feeDescriptionTypeMap.get(type) || 'Unspecified Fee'
}
